import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import LambdaApi from '../../utils/axiosLambda';
// import TestApi from '../../utils/axiosTest';
import { Card, } from "react-bootstrap";
import styled from 'styled-components';
import { DateContext } from '../../contexts/DateContext';

import { media } from '../../utils/styleUtils';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Message = styled.p`
  font-size: 2.25em;
  margin-bottom: 0px;

  // スマホレイアウト用の設定
  ${media.smartphone`
    font-size: 1.2em;
  `}
`;

const TodayMessage = () => {
  const { datetime } = useContext(DateContext);
  const date = datetime.format('YYYY-MM-DD');
  const time = datetime.format('HH:mm');

  const location = useLocation();
  const [messageInfo, setMessageInfo] = useState('');

  // 今日のメッセージ取得
  const getTodyaMessage = async(req_messageInfo, reload) => {
    // 前回取得したメッセージの表示時間内であれば取得処理は行わない
    // ただし画面遷移時は再取得を行う
    if (!reload && req_messageInfo && req_messageInfo.date == date && req_messageInfo.time_to >= time) {
      return;
    }

    const apiCsrfToken = cookies.get('api-csrf-token');
    const query = new URLSearchParams({date: date, time: time});
    const res = await LambdaApi.get('/service/get_today_message?' + query, {headers: {'X-CSRF-TOKEN': apiCsrfToken}});
    const data = res.data;

    if (data[0].status !== 'success') return;

    setMessageInfo(data[1]);
  }

  useEffect(() => {
    getTodyaMessage(messageInfo, false);
  }, [time]);

  useEffect(() => {
    getTodyaMessage(messageInfo, true);
  }, [location.pathname]);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Message>
          {messageInfo.contents ?? '　'}
        </Message>
      </Card.Body>
    </Card>
  );

}

export default TodayMessage;
