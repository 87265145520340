import { css } from 'styled-components';

// styled-componentsでmedia queryを使用するためのUtil
// 参考：https://gist.github.com/kenmori/60bf7b67819061f41ce960617c035955#mediaquery
//
// ※ styled-componentsを使用できない箇所はreact-responsiveを使用する
export const media = {
  smartphone: (...args) => css`
    @media (max-width: 500px) {
      ${css(...args)};
    }
  `,
}
