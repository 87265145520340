import services from '../utils/services';
import LambdaApi from '../utils/axiosLambda';
// import TestApi from '../utils/axiosTest';
import { Cookies } from 'react-cookie';
import moment from 'moment';

const delay = (timeout) => {
    return new Promise((res) => setTimeout(res, timeout));
};

const cookies = new Cookies();

services.onPost('/api/account/login').reply(async (config) => {
    try {

        const { login_id, password, retention } = JSON.parse(config.data);
        const hold_period = retention ? 30:1;
        const params = {login_id:login_id ,password:password,period:hold_period};
        let res;
        // LambdaAPIリクエスト
        // http://127.0.0.1:3001/
        res = await LambdaApi.post('/service/login_user',params);
        res = res.data;

        let result;
        switch(res.status){
          case 'failed':
            result = [400, { message: '認証に失敗しました。\nメールアドレス・パスワードを確認してください' }];
            break;
          case 'error':
            result = [400, { message: '認証に失敗しました。\n例外エラー' }];
            break;
          case 'success':
            var exp = moment().add(hold_period,'days').format("YYYY/MM/DD");
            exp = new Date(exp);
            cookies.set('api-csrf-token', res['csrf_access_token'], {expires:exp, sameSite: 'strict', secure: true, path: '/'});
            result = [200, {
                user: {
                    name: res.name,
                    userKind: res.user_kind,
                    cityCode: res.city_code,
                    pusher_app_key: res.pusher_app_key,
                    pusher_cluster: res.pusher_cluster,
                },
            }];
            break;
        }
        return result;

    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onGet('/api/account/me').reply(async (config) => {
    try {
        const apiCsrfToken = cookies.get('api-csrf-token');
        let res;
        // LambdaAPIリクエスト
        res = await LambdaApi.get('/service/account_user', {headers: {'X-CSRF-TOKEN': apiCsrfToken}});
        res = res.data;

        let result;
        switch(res.status){
          // レスポンスステータスが失敗の場合終了
          case 'failed':
            result = [401, { message: 'Invalid Token' }];
            break;
          case 'error':
            result = [401, { message: 'Invalid Token' }];
            break;
          // レスポンスステータスが成功の場合処理継続
          case 'success':
            result = [200, {
              user: {
                name: res.name,
                userKind: res.user_kind,
                cityCode: res.city_code,
                pusher_app_key: res.pusher_app_key,
                pusher_cluster: res.pusher_cluster,
              }
            }];
            break;
        }
        return result;

    } catch (err) {
      console.error(err)
      switch (err.response?.status) {
        case 401:
          return [401, { message: 'Invalid Token' }];
        default:
          return [500, { message: 'Server Error' }];
      }
    }
});

services.onPost('/api/account/logout').reply(async () => {
  try {
    const apiCsrfToken = cookies.get('api-csrf-token');
    await LambdaApi.post('/service/logout',{},{headers: {'X-CSRF-TOKEN': apiCsrfToken}});
    cookies.remove('api-csrf-token',{maxAge:0, path:'/'})

    return [200, {}]
  } catch (err) {
    console.error(err);
    return [500, { message: 'Server Error' }];
  }
});


services.onPost('/api/account_link_line').reply(async (config) => {
  try {
      const apiCsrfToken = cookies.get('api-csrf-token');
      const { login_id, password } = JSON.parse(config.data);
      const params = {login_id:login_id ,password:password};
      let res;
      // LambdaAPIリクエスト
      res = await LambdaApi.post('/service/account_link_line', params,{headers: {'X-CSRF-TOKEN': apiCsrfToken}});
      res = res.data;

      let result;
      switch(res.status){
        case 'success':
          result = [200, { nonce: res.nonce }];
          break;
        case 'failed':
          result = [400, { message: '認証に失敗しました。\nメールアドレス・パスワードを確認してください' }];
          break;
        case 'error':
        default:
          result = [400, { message: '認証に失敗しました。\n例外エラー' }];
          break;
      }
      return result;

  } catch (err) {
      console.error(err);
      return [500, { message: 'Server Error' }];
  }
});
