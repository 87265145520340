import { useMediaQuery } from 'react-responsive';

import logo from '../../assets/images/icon/cpc_logo/cpc_logo_01.png'

const CpcLogo = () => {
  const isSmartphone = useMediaQuery({query: '(max-width: 500px)'})
  const height = isSmartphone ? '80px' : '120px';

  return (
    <img src={logo} alt="logo" height={height} />
  )
}

export default CpcLogo;
