import { createContext, useState, useEffect } from 'react';
import moment from 'moment';

const initialState = {
  date: moment(),
}

const DateContext = createContext(initialState);

const DateProvider = ({ children }) => {
  const[datetime, setDatetime] = useState(moment());

  // 1秒ごとに変更前の時刻と現在時刻を比較して、分が変わったタイミングで更新する
  useEffect(() => {
    const timer =setInterval(() => {
      setDatetime((pre_date) => {
        let now = moment();
        return pre_date.isSame(now, 'minute') ? pre_date : now;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  }, []);

  return (
    <DateContext.Provider value={{datetime}}>
      {children}
    </DateContext.Provider>
  );
}

export {DateContext, DateProvider};
