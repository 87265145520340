import styled from 'styled-components';
import { media } from '../../utils/styleUtils';

const DateText = styled.p`
  font-size: 2.75em;
  margin-bottom: 0;

  // スマホレイアウト用の設定
  ${media.smartphone`
    font-size: 1.6em;
  `}
`;

const Normal = (props) => {
  return (
    <DateText>
      {props.date.format('LLLL')}
    </DateText>
  );
}

export default Normal;
