import { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';

const WetherIcon = (props) => {
  const [src, setSrc] = useState(null);

  const loadImage = (name) => {
    import(`../../assets/images/icon/weather_icon/${name}`).then(image =>{
      setSrc(image.default);
    });
  }

  const isSmartphone = useMediaQuery({query: '(max-width: 500px)'})
  const width = isSmartphone ? '80' : '140';

  useEffect(() => {
    loadImage(props.name);
  }, []);

  return (
    <img src={src} alt={props.name} width={width} />
  );

}

export default WetherIcon;
