import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { JWTProvider } from "./contexts/JWTContext";

import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./config/constant";

// ブラウザの自動リロード間隔(分)
const autoReloadInterval = 60;

const App = () => {

  useEffect(() => {
    // ブラウザの自動リロード処理
    //   タブレットをスリープしている間はsetIntervalのカウントがストップする
    //   そのため、レンダリング時にリロードを実行する時刻を保持しておいて、現在時刻がその時刻を過ぎていた場合にリロード処理を実行する
    //   (リロードするかどうかの判定は30秒ごとにチェック)
    const autoReload = () => {
      const reloadTime = Date.now() + (autoReloadInterval * 60 * 1000);

      return setInterval(() => {
        if (Date.now() < reloadTime) return;

        window.location.reload();
      }, 30 * 1000);
    }

    const interval = autoReload();

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <React.Fragment>
        <Router basename={BASENAME}>
            <JWTProvider>
              {renderRoutes(routes)}
            </JWTProvider>
        </Router>
    </React.Fragment>
  );
};

export default App;
