import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import MonitorLayout from "./layouts/MonitorLayout";
import ParticipantLayout from "./layouts/ParticipantLayout";
import CityLayout from "./layouts/CityLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";
import MonitorGuard from "./components/Auth/MonitorGuard";
import ParticipantGuard from "./components/Auth/ParticipantGuard";
import CityGuard from "./components/Auth/CityGuard";

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route key={i} path={route.path} exact={route.exact} render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signin-1',
    component: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: true,
    path: '/account_link/line',
    component: lazy(() => import('./views/auth/account-link/line'))
  },

  // モニター用
  {
    path: '/monitor',
    layout: MonitorLayout,
    guard: MonitorGuard,
    routes: [
      // メニュー画面
      {
        exact: true,
        path: '/monitor/menu',
        component: lazy(() => import('./views/monitorMenu'))
      },
      // 活動レポート
      {
        exact: true,
        path: '/monitor/report/:dayOrWeek/:startDay',
        component: lazy(() => import('./views/monitorReport'))
      },
      // 活動記録
      {
        exact: true,
        path: '/monitor/activity_diaries',
        component: lazy(() => import('./views/activityDiaries'))
      },
      // プロフィール照会
      {
        exact: true,
        path: '/monitor/profile',
        component: lazy(() => import('./views/profile/monitor/inquiry'))
      },
      {
        exact: true,
        path: '*',
        component: () => <Redirect to={BASE_URL}/>
      },
    ]
  },
  // 関係者用
  {
    path: '/participant',
    layout: ParticipantLayout,
    guard: ParticipantGuard,
    routes: [
      // モニター一覧
      {
        exact: true,
        path: '/participant/target_person',
        component: lazy(() => import('./views/dashboard/DashDefault'))
      },
      // 活動レポート
      {
        exact: true,
        path: '/participant/target_person/detail/:contractId/:dayOrWeek/:startDay',
        component: lazy(() => import('./views/targetPerson/Detail'))
      },
      // プロフィール照会
      {
        exact: true,
        path: '/participant/profile',
        component: lazy(() => import('./views/profile/participant/inquiry'))
      },
      {
        exact: true,
        path: '*',
        component: () => <Redirect to={BASE_URL}/>
      },
    ]
  },
  // 自治体用
  {
    path: '/city',
    layout: CityLayout,
    guard: CityGuard,
    routes: [
      // モニター一覧
      {
        exact: true,
        path: '/city/target_person',
        component: lazy(() => import('./views/dashboard/DashDefault'))
      },
      // 活動レポート
      {
        exact: true,
        path: '/city/target_person/detail/:contractId/:dayOrWeek/:startDay',
        component: lazy(() => import('./views/targetPerson/Detail'))
      },
      // プロフィール照会
      {
        exact: true,
        path: '/city/profile',
        component: lazy(() => import('./views/profile/city/inquiry'))
      },
      {
        exact: true,
        path: '*',
        component: () => <Redirect to={BASE_URL}/>
      },
    ]
  },

  {
    path: '*',
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '*',
        component: lazy(() => import('./components/HomeRedirect'))
      },
    ]
  },

];

export default routes;
