import Normal from "./Normal";
import Kyureki from "./Kyureki";
import { DateContext } from '../../contexts/DateContext';
import { useContext } from "react";

const DateViewer = () => {

  const { datetime } = useContext(DateContext);

  return (
    <>
      {/* 通常の日付 */}
      <Normal date={datetime} />
      {/* 旧暦 */}
      <Kyureki date={datetime} />
    </>
  );
}

export default DateViewer;
