// args:メニューの制御等で使用する値
//      (各レイアウトフォルダのNavigationで設定)
const MenuItems = (args={}) => {
  let menu = null;

  switch (args.userKind) {
    // 関係者
    case '2':
      menu = [
        {
          id: 'targetperson',
          title: 'モニター一覧',
          type: 'item',
          url: '/participant/target_person',
          classes: 'nav-item',
          icon: 'feather icon-home',
          breadcrumbs: false
        },
      ];

      // 結コンテンツのボタンはフラグで表示切替する
      if (args.yuiContentsViewFlg) {
        menu.push({
          id: 'yuiContents',
          title: '結コンテンツ',
          type: 'item',
          url: 'https://www.youtube.com/channel/UC_jN74P2s9LmYyXCA6nDA9A',
          external: true,
          classes: 'nav-item',
          icon: 'feather icon-box',
          breadcrumbs: false
        });
      }

      // 本番用LINEアカウント
      let lineLink = 'https://lin.ee/VwpgXvD';
      // 開発環境の場合は開発用アカウントをセット
      if (args.developmentEnvFlg) {
        lineLink = 'https://lin.ee/YRxO6f4';
      }

      menu.push({
        id: 'line_add_friend',
        title: 'LINEアカウント連携',
        type: 'item',
        url: lineLink,
        external: true,
        classes: 'nav-item',
        icon: 'feather icon-message-square',
        breadcrumbs: false
    });

      break;

    // 自治体
    case '3':
      menu = [
        {
          id: 'targetperson',
          title: 'モニター一覧',
          type: 'item',
          url: '/participant/target_person',
          classes: 'nav-item',
          icon: 'feather icon-home',
          breadcrumbs: false
        },
      ];
      break;

    default:
      break;
  }

  return {
    items : [
      {
        id: 'mainmanu',
        title: 'メインメニュー',
        type: 'group',
        children: menu,
      },
    ]
  };
};

export default MenuItems;
