import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import useAuth from '../../hooks/useAuth';
import LambdaApi from '../../utils/axiosLambda';
// import TestApi from '../../utils/axiosTest';
import WetherIcon from "../icon/WetherIcon";
import { media } from '../../utils/styleUtils';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Text = styled.p`
  font-size: 1.5em;
  margin-bottom: 0;

  // スマホレイアウト用の設定
  ${media.smartphone`
    font-size: 1.0em;
  `}
`;

const ColNoPad = styled(Col)`
  padding-right: 0px;
  padding-left: 0px;
  margin-top: auto;
  margin-bottom: auto;
`;

const WeatherViewer = () => {
  const {user} = useAuth();
  const [weather, setWeather] = useState(null);

  // 天気予報取得
  const getWeather = async() => {
    const apiCsrfToken = cookies.get('api-csrf-token');
    const query = new URLSearchParams({ city_code: user.cityCode });
    const res = await LambdaApi.get(`/service/get_weather?${query}`, {headers: {'X-CSRF-TOKEN': apiCsrfToken}});
    const data = res.data;

    if (data[0].status !== 'success') return;

    setWeather(data[1]);
  }

  useEffect(() => {
    getWeather();
  }, []);

  if (weather) {
    return (
      <Row>
        <ColNoPad
          lg={{ span:4, offset:1 }}
          sm={{ span:4, offset:0 }}
          xs={{ span:4, offset:0 }}
        >
          <Text>{weather.area_name}</Text>
          <Text>降水確率 {weather.rain_per}%</Text>
        </ColNoPad>
        <ColNoPad
          lg={{ span:4 }}
          sm={{ span:4 }}
          xs={{ span:4 }}
        >
          <WetherIcon name={weather.icon} />
        </ColNoPad>
        <ColNoPad
          lg={{ span:3 }}
          sm={{ span:4 }}
          xs={{ span:4 }}
        >
          <Text>気温 (℃)</Text>
          <Text>　最高 {weather.temp_max}</Text>
          <Text>　最低 {weather.temp_min}</Text>
        </ColNoPad>
      </Row>
    );
  }

  return (
    <></>
  );
}

export default WeatherViewer;
