import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useLocation } from "react-router";

import LambdaApi from '../../utils/axiosLambda';
// import TestApi from '../../utils/axiosTest';

import useAuth from '../../hooks/useAuth';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const AuthGuard = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn, logout } = useAuth();
  const apiCsrfToken = cookies.get('api-csrf-token');


    useEffect(()=> {
      const isloggedIn = async () => {
        await LambdaApi.get('/service/is_logged_in',{headers: {'X-CSRF-TOKEN': apiCsrfToken}})
          .then(res => {
            console.log('認証成功');

          }).catch(error => {
            console.log('認証失敗');
            logout();
        });
      }

      isloggedIn();
    }, [location.pathname]);


    if (!isLoggedIn) {
        return <Redirect to="/auth/signin-1" />;
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default AuthGuard;
