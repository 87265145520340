import React, { createContext, useEffect, useReducer, useContext } from 'react';

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT, RESET } from "../store/actions";
//import axios from '../services/axios';
import axios from '../utils/axios';
import accountReducer from '../store/accountReducer';
import Loader from "../components/Loader/Loader";
import { ConfigContext } from './ConfigContext';

import { useLocation } from "react-router";

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => { }
});

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const configContext = useContext(ConfigContext);
  const { dispatch:configDispatch } = configContext;
  const location = useLocation();

  const login = async (login_id, password,retention) => {
    const response = await axios.post('/api/account/login', { login_id, password, retention });
    const { user } = response.data;
    dispatch({
      type: LOGIN,
      payload: {
        user
      }
    });
  };

  const logout = async() => {
    await axios.post('/api/account/logout');
    dispatch({ type: LOGOUT });

    // レイアウトのクリア
    configDispatch({ type: RESET });
    document.body.setAttribute('class', '');
    document.body.setAttribute('style', '');
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (['/auth/signin-1', '/account_link/line'].includes(location.pathname)) {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null}
          });
          return;
        }

        const response = await axios.get('/api/account/me');
        const { user } = response.data;

        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: true,
            user
          }
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout}}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
