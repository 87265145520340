import { useEffect } from "react";
import useWindowSize from "./useWindowSize";

const useOutsideClick = (ref, callback) => {
    const windowSize = useWindowSize();

    const handleClick = e => {
      // ハンバーガーメニューのクリック判定(まれに対象がspanになるため親要素も参照)
      if (windowSize.width < 992 &&
          (e.target.id === 'mobile-collapse' || ( e.target.offsetParent && e.target.offsetParent.id === 'mobile-collapse'))){
          callback();
      }
      if (ref.current && !ref.current.contains(e.target)) {
          callback();
      }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

export default useOutsideClick;
