import React, { useContext, useState } from 'react';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {ConfigContext} from "../../../../contexts/ConfigContext";
import useAuth from '../../../../hooks/useAuth';

// import avatar1 from '../../../../assets/images/user/avatar-1.jpg';

const NavRight = () => {
  const configContext = useContext(ConfigContext);
  const { logout,user } = useAuth();
  const { rtlLayout } = configContext.state;

  const handleLogout = async () => {
    try {
      //handleClose();
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <ListGroup as='ul' bsPrefix=' ' className="navbar-nav ml-auto" id='navbar-right'>
        {/* <ListGroup.Item as='li' bsPrefix=' '>
          <Dropdown alignRight={!rtlLayout}>
            <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
              <i className="feather icon-bell icon"/>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="notification notification-scroll">
              <div className="noti-head">
                <h6 className="d-inline-block m-b-0">お知らせ</h6>
                <div className="float-right">
                  <Link to='#' className="m-r-10">全て既読にする</Link>
                </div>
              </div>
              <PerfectScrollbar>
                <ListGroup as='ul' bsPrefix=' ' variant="flush" className="noti-body">
                  <ListGroup.Item as='li' bsPrefix=' ' className="n-title">
                    <p className="m-b-0">NEW</p>
                  </ListGroup.Item>
                  <ListGroup.Item as='li' bsPrefix=' '  className="notification">
                    <Media>
                      <Media.Body>
                        <p>
                          <strong>新しいお知らせです！</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10"/>30 分前
                          </span>
                        </p>
                        <p>新しいお知らせの本文がここに入ります。</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                </ListGroup>
              </PerfectScrollbar>
              <div className="noti-footer">
                  <Link to='#'>全て表示</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item> */}

        <ListGroup.Item as='li' bsPrefix=' '>
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle as='li' variant='link' role='button' id="dropdown-basic">
              <i className="icon feather icon-settings"/>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                {/* <img src={avatar1} className="img-radius" alt="User Profile"/> */}
                <span>{user.name}</span>
                <Link to='#' className="dud-logout" title="Logout">
                  <i className="feather icon-log-out"/>
                </Link>
              </div>
              <ListGroup as='ul' bsPrefix=' ' variant='flush' className="pro-body">
                {/* <ListGroup.Item as='li' bsPrefix=' '>
                  <Link to='#' className="dropdown-item">
                    <i className="feather icon-settings"/>
                    個人設定
                  </Link>
                </ListGroup.Item> */}
                <Dropdown.Item href="#" bsPrefix=' ' active={false}>
                  <ListGroup.Item as='li' bsPrefix=' '>
                    <Link to='/city/profile' className="dropdown-item py-3">
                      <i className="feather icon-user"/>
                      プロフィール
                    </Link>
                  </ListGroup.Item>
                </Dropdown.Item>
                <ListGroup.Item as='li' bsPrefix=' '>
                  <Link to='#' className="dropdown-item py-3" onClick={handleLogout}>
                    <i className="feather icon-log-out"/>
                    ログアウト
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default NavRight;
