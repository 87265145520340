import { useEffect, useState } from "react"
import LambdaApi from '../../utils/axiosLambda';
// import TestApi from '../../utils/axiosTest';
import styled from 'styled-components';
import { media } from '../../utils/styleUtils';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const DateText = styled.p`
  font-size: 2.25em;

  // スマホレイアウト用の設定
  ${media.smartphone`
    font-size: 1.3em;
  `}
`;

const Kyureki = (props) => {
  const date = props.date.format('YYYY-MM-DD')
  const [kyureki, setKyureki] = useState('');

  // 旧暦情報取得
  const getKyureki = async() => {
    const apiCsrfToken = cookies.get('api-csrf-token');
    const query = new URLSearchParams({date:date});
    const res = await LambdaApi.get('/service/get_kyureki?' + query, {headers: {'X-CSRF-TOKEN': apiCsrfToken}});
    const data = res.data;

    if (data[0].status !== 'success') return;

    setKyureki(data[1]);
  }

  useEffect(() => {
    getKyureki();
  }, [date]);

  if (kyureki) {
    return (
      // 下記のフォーマットで表示
      //   旧暦 2021年1月1日 先勝
      //   (閏月の場合)旧暦 2021年閏1月1日 先勝
      <DateText>
        {`旧暦 ${kyureki.year}年${kyureki.uruduki_flg ? '閏':''}${kyureki.month}月${kyureki.day}日 ${kyureki.rokuyou}`}
      </DateText>
    );

  } else {
    // 旧暦情報が取得できなかった場合はなにも表示しない
    return(<></>);
  }

}

export default Kyureki;
