import React, { useContext, useEffect, useRef } from 'react';

import Navigation from "./Navigation";
import NavBar from "./NavBar";
// import Breadcrumb from "../AdminLayout/Breadcrumb";
// import NavLogo from "./Navigation/NavLogo";

import useWindowSize from "../../hooks/useWindowSize";
// import useOutsideClick from "../../hooks/useOutsideClick";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";
import styled from 'styled-components';
import TodayMessage from '../../components/Message/TodayMessage';
import { DateProvider } from '../../contexts/DateContext';
import { media } from '../../utils/styleUtils';

const font_color = '#000';

const CustomDiv = styled.div`
  // 文字色
  a, p, span, label, small, tspan, .form-control, .table, .btn, .rdtPicker {
    color: ${font_color};
  }
  .apexcharts-legend-text {
    color: ${font_color} !important;
  }

  // トグルスイッチのサイズ定義(custom-switch-md)
  .custom-switch.custom-switch-md .custom-control-label {
    padding-left: 1rem;
    font-size: 1.4rem;
  }
  .custom-switch.custom-switch-md .custom-control-label::before {
    height: 1.5rem;
    width: 2.75rem;
    border-radius: 3rem;
  }
  .custom-switch.custom-switch-md .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
  }
  .custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(1.5rem - 0.25rem));
  }

  // 日付入力エリアのスタイル
  .date-input-area {
    input {
      font-size: 1.5rem;
    }
    th,td {
      font-size: 1.25em;
      padding: 8px;
    }
    button {
      font-size: 1.5rem;
      height: 58px;
      margin-bottom: 8px !important;
    }
  }

  // 活動レポートチャートの文字サイズ
  //   横軸、縦軸の値の文字サイズをここで変更すると文字が見切れるため、チャート作成時に指定
  .apexcharts-tooltip {
    font-size: 1.2rem;
  }
  .apexcharts-legend-text {
    font-size: 22px !important;
  }


  // コンテンツの位置調整
  .custom_vertical_layout > .pcoded-content {
    margin-top: 300px !important;
  }
  .custom_vertical_layout > .pcoded-content > .pcoded-inner-content {
    margin-top: -320px !important;
    min-height: auto !important;
  }

  .custom_horizontal_layout > .pcoded-content {
    margin-top: 180px !important;
  }
  .custom_horizontal_layout > .pcoded-content > .pcoded-inner-content {
    margin-top: -220px !important;
    min-height: auto !important;
  }

  // スマホレイアウト用の設定
  ${media.smartphone`
    // 日付入力エリアのスタイル
    .date-input-area {
      input {
        font-size: 0.8rem;
        padding: 10px;
      }
      th,td {
        font-size: 1em;
        padding: 1px;
      }
      button {
        font-size: 1.0em;
        height: 30px;
      }
    }

    // トグルスイッチのサイズ定義(custom-switch-md)
    .custom-switch.custom-switch-md .custom-control-label {
      padding-left: 0;
      font-size: 1.0em;
    }
    .custom-switch.custom-switch-md .custom-control-label::before {
      height: 1.0em;
      width: 1.75em;
      border-radius: 2.0em;
    }
    .custom-switch.custom-switch-md .custom-control-label::after {
      width: calc(1.0em - 4px);
      height: calc(1.0em - 4px);
      border-radius: calc(2.0em - (1.0em / 2));
    }
    .custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(calc(1.0em - 0.25em));
    }
  `}
`;

const MonitorLayout = ({ children }) => {
  const windowSize = useWindowSize();
  // const ref = useRef();
  const configContext = useContext(ConfigContext);

  // const { collapseMenu, layout, subLayout, headerFixedLayout } = configContext.state;

  const { dispatch } = configContext;


  useEffect(() => {

    dispatch({
      type: actionType.LAYOUT6_BACKGROUND,
      layout6Background: 'linear-gradient(to bottom, #ffffff, #f4f7fa 60%, #f4f7fa)',
      layout6BackSize: 'cover',
    });

    dispatch({
      type: actionType.HEADER_BACK_COLOR,
      headerBackColor: 'header-default',
    });
  }, []);

  // useOutsideClick(ref, () => {
  //   if (collapseMenu) {
  //     dispatch({type: actionType.COLLAPSE_MENU});
  //   }
  // });

  // useEffect(() => {
  //   if (windowSize.width > 992 && windowSize.width <= 1024 && layout !== 'horizontal') {
  //     dispatch({type: actionType.COLLAPSE_MENU});
  //   }

  //   if (layout === 'horizontal' && windowSize.width < 992) {
  //     dispatch({type: actionType.CHANGE_LAYOUT, layout: 'vertical'});
  //   }
  // }, [dispatch, layout, windowSize]);

  // const mobileOutClickHandler = () => {
  //   if (windowSize.width < 992 && collapseMenu) {
  //     dispatch({type: actionType.COLLAPSE_MENU});
  //   }
  // };

  let mainClass = ['pcoded-wrapper'];
  // if (layout === 'horizontal' && subLayout === 'horizontal-2') {
  //   mainClass = [...mainClass, 'container'];
  // }

  if (windowSize.width < 992) {
    mainClass = [...mainClass, 'custom_vertical_layout'];
  } else {
    mainClass = [...mainClass, 'custom_horizontal_layout'];
  }

  let common = (
    <React.Fragment>
      <Navigation/>
    </React.Fragment>
  );

  let mainContainer = (
    <React.Fragment>
      <NavBar/>
      <div className="pcoded-main-container ml-0" style={{ minHeight: 'auto' }}>
        <CustomDiv>
          <div className={mainClass.join(' ')}>
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                {/* <Breadcrumb/> */}
                {/* <NavLogo /> */}
                {/* <Header /> */}
                <DateProvider>
                  <TodayMessage />
                </DateProvider>
                {children}
              </div>
            </div>
          </div>
        </CustomDiv>
      </div>
    </React.Fragment>
  );

  // if (windowSize.width < 992) {
  //   let outSideClass = ['nav-outside'];
  //   if (collapseMenu) {
  //     outSideClass = [...outSideClass, 'mob-backdrop'];
  //   }
  //   if (headerFixedLayout) {
  //     outSideClass = [...outSideClass, 'mob-fixed'];
  //   }

  //   common = (
  //     <div className={outSideClass.join(' ')} ref={ref}>
  //       {common}
  //     </div>
  //   );

  //   mainContainer = (
  //     <div className='pcoded-outside' onClick={() => mobileOutClickHandler}>
  //       {mainContainer}
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      {common}
      {mainContainer}
    </React.Fragment>
  );
};

export default MonitorLayout;
