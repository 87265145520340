import 'moment/locale/ja';
import { Col, Row } from "react-bootstrap";
import DateViewer from "../../../components/Date/DateViewer";
import WeatherViewer from "../../../components/Weather/WeatherViewer";
import NavLeft from "../NavBar/NavLeft";
import NavRight from "../NavBar/NavRight";
import { DateProvider } from '../../../contexts/DateContext';

const Header = () => {
  return (
    <Row className="w-100 mx-0 ">
      <Col
        lg={{span:1, order:1}}
        sm={{span:2, order:1}}
        xs={{span:3, order:1}}
      >
        <NavLeft />
      </Col>
      <Col
        lg={{span:6, order:2}}
        sm={{span:9, order:2}}
        xs={{span:12, order:4}}
      >
        <DateProvider>
          <DateViewer />
        </DateProvider>
      </Col>
      <Col
        lg={{span:4, order:3, offset:0}}
        sm={{span:8, order:4, offset:2}}
        xs={{span:7, order:2, offset:0}}
      >
        <WeatherViewer />
      </Col>
      <Col
        lg={{span:1, order:4, offset:0}}
        sm={{span:1, order:3, offset:0}}
        xs={{span:2, order:3, offset:0}}
      >
        <NavRight />
      </Col>
    </Row>
  );

}

export default Header;
