import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const axiosLambda = axios.create({
  // baseURL: `https://g583ofmjf0.execute-api.ap-northeast-1.amazonaws.com/Prod/`, //開発環境用
  // baseURL: `https://mimamori-dev.cplusc.co.jp/api/`,   //CPC開発環境
  baseURL: `https://mimamori-user.cplusc.co.jp/api/`,   //cpc本番環境用
  // baseURL: `https://cwiluinsxe.execute-api.ap-northeast-1.amazonaws.com/Prod/`,   //CPC開発環境(API Gateway)
  //baseURL: `https://8dfxlmkcz6.execute-api.ap-northeast-1.amazonaws.com/Prod/`,   //cpc本番環境用(API Gateway)
  withCredentials: true,
});

axiosLambda.interceptors.response.use(
  (response) => {
    return response;
  }, (error) => {
    switch (error.response?.status) {
      case 401:
        window.location.pathname = '/gov-dashboard/auth/signin-1';
        cookies.remove('api-csrf-token',{maxAge:0, path: '/'});
        break;
      default:
        break;
    }
  }
);

export default axiosLambda;
