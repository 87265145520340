import React, { useContext } from 'react';
import CpcLogo from '../../../../components/icon/CpcLogo';
// import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import useWindowSize from "../../../../hooks/useWindowSize";
// import { ConfigContext } from "../../../../contexts/ConfigContext";

const NavLeft = () => {
  // const windowSize = useWindowSize();

  // const configContext = useContext(ConfigContext);
  // const { rtlLayout } = configContext.state;
  // let dropdownRightAlign = false;
  // if (rtlLayout) {
  //   dropdownRightAlign = true;
  // }

  // let navItemClass = ['nav-item'];
  // if (windowSize.width <= 575) {
  //   navItemClass = [...navItemClass, 'd-none'];
  // }

  return (
    <React.Fragment>
      {/* <span className="navbar-brand">
        あんしんみまもり
      </span> */}

      <Link className="navbar-brand" to="#">
        <CpcLogo />
      </Link>

      {/* <ListGroup as='ul' bsPrefix=' ' className="navbar-nav mr-auto">
        <ListGroup.Item as='li' bsPrefix=' ' className={navItemClass.join(' ')}>
          <Dropdown alignRight={dropdownRightAlign}>
            <Dropdown.Toggle variant={'link'} id="dropdown-basic">
              メニュー
            </Dropdown.Toggle>
            <ul>
              <Dropdown.Menu>
                <li><Link to='#' className="dropdown-item">項目１</Link></li>
                <li><Link to='#'className="dropdown-item">項目２</Link></li>
                <li><Link to='#' className="dropdown-item">項目３</Link></li>
              </Dropdown.Menu>
            </ul>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup> */}
    </React.Fragment>
  );
};

export default NavLeft;
