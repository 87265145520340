import React, { useContext, useEffect, useRef } from 'react';

import Navigation from "./Navigation";
import NavBar from "./NavBar";
// import Breadcrumb from "./Breadcrumb";

import useWindowSize from "../../hooks/useWindowSize";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";

import styled from 'styled-components';

// コンテンツの位置調整
const CustomDiv = styled.div`
  .custom-header > .pcoded-content {
    margin-top: 320px;
  }
  .custom-header > .pcoded-content > .pcoded-inner-content {
    margin-top: -320px;
    min-height: calc(100vh + 270px);
  }

  .default-header > .pcoded-content {
    margin-top: 200px;+
  }
  .default-header > .pcoded-content > .pcoded-inner-content {
    margin-top: -200px;
    min-height: calc(100vh + 150px);
  }
`;

const CityLayout = ({ children }) => {
  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);

  const { collapseMenu, layout, subLayout, headerFixedLayout } = configContext.state;
  const { dispatch } = configContext;


  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch({type: actionType.COLLAPSE_MENU});
    }
  });

  useEffect(() => {
    if (windowSize.width > 992 && windowSize.width <= 1024 && layout !== 'horizontal') {
      dispatch({type: actionType.COLLAPSE_MENU});
    }

    if (layout === 'horizontal' && windowSize.width < 992) {
      dispatch({type: actionType.CHANGE_LAYOUT, layout: 'vertical'});
    }
  }, [dispatch, layout, windowSize]);

  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({type: actionType.COLLAPSE_MENU});
    }
  };

  let mainClass = ['pcoded-wrapper'];
  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    mainClass = [...mainClass, 'container'];
  }

  if (windowSize.width < 768) {
    mainClass = [...mainClass, 'custom-header'];
  } else {
    mainClass = [...mainClass, 'default-header'];
  }


  let common = (
    <React.Fragment>
      <Navigation/>
    </React.Fragment>
  );

  let mainContainer = (
    <React.Fragment>
      <NavBar/>
      <div className="pcoded-main-container">
        <CustomDiv>
          <div className={mainClass.join(' ')}>
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                {/* <Breadcrumb/> */}
                {children}
              </div>
            </div>
          </div>
        </CustomDiv>
      </div>
    </React.Fragment>
  );

  if (windowSize.width < 992) {
    let outSideClass = ['nav-outside'];
    if (collapseMenu) {
      outSideClass = [...outSideClass, 'mob-backdrop'];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, 'mob-fixed'];
    }

    common = (
      <div className={outSideClass.join(' ')} ref={ref}>
        {common}
      </div>
    );

    mainContainer = (
      <div className='pcoded-outside' onClick={() => mobileOutClickHandler}>
        {mainContainer}
      </div>
    );
  }

  return (
    <React.Fragment>
      {common}
      {mainContainer}
    </React.Fragment>
  );
};

export default CityLayout;
